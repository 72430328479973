//==============================================================================
// Container with Header
//
// Container module with optionally linked header title and header text
//==============================================================================
import * as React from 'react';
import classnames from 'classnames';

import { clsHelper } from '../../utilities/class-name-helper';

import { RichTextComponent } from '@msdyn365-commerce/core';
import { Heading } from '@msdyn365-commerce-modules/utilities';

import { IContainerWithHeaderData } from './container-with-header.data';
import {
    IContainerWithHeaderConfig,
    IContainerWithHeaderProps,
    ILinkData,
    ILinksData,
    ITitleData
} from './container-with-header.props.autogenerated';

//==============================================================================
// CLASS NAME UTILITY
//==============================================================================
const BASE_CLASS = 'container-with-header';
const cls = (fragment?: string) => clsHelper(BASE_CLASS, fragment);

//==============================================================================
// CLASS DEFINITION
//==============================================================================
/**
 * ContainerWithHeader component
 * @extends {React.PureComponent<IContainerWithHeaderProps<IContainerWithHeaderData>>}
 */
//==============================================================================
class ContainerWithHeader extends React.PureComponent<IContainerWithHeaderProps<IContainerWithHeaderData>> {
    //==========================================================================
    // PUBLIC METHODS
    //==========================================================================
    //------------------------------------------------------
    // Render function
    //------------------------------------------------------
    public render(): JSX.Element {
        const { config, slots } = this.props;
        const hasSlots = !!slots?.contentBlock?.length;
        const hasLinks = !!config.links?.length;

        return (
            <div className={classnames(BASE_CLASS, config.className)}>
                {config && this._renderHeader(config)}
                {hasSlots && this._renderSlotItems(slots.contentBlock)}
                {hasLinks && this._renderLinks(config.links!)}
            </div>
        );
    }

    //==========================================================================
    // PRIVATE METHODS
    //==========================================================================
    //------------------------------------------------------
    //------------------------------------------------------
    private readonly _renderHeader = (config: IContainerWithHeaderConfig): JSX.Element => {
        return (
            <div className={cls('header')}>
                {config.title?.text && this._renderLinkedTitle(config)}
                {config.text && <RichTextComponent text={config.text} className={cls('text')} />}
            </div>
        );
    };

    //------------------------------------------------------
    //------------------------------------------------------
    private readonly _renderLinkedTitle = (config: IContainerWithHeaderConfig): JSX.Element => {
        const { link, title } = config;

        if (link?.linkUrl.destinationUrl) {
            return (
                <a href={link.linkUrl.destinationUrl} aria-label={link.ariaLabel}>
                    {this._renderTitle(title!)}
                </a>
            );
        } else {
            return this._renderTitle(title!);
        }
    };

    //------------------------------------------------------
    //------------------------------------------------------
    private readonly _renderTitle = (title: ITitleData): JSX.Element => {
        return (
            <Heading
                className={cls('title')}
                headingTag={title.tag}
                text={title.text}
            />
        );
    };

    //------------------------------------------------------
    //------------------------------------------------------
    private readonly _renderSlotItems = (items: React.ReactNode[]): JSX.Element => {
        return (
            <div className={cls('slots')}>
                {items.map((slot: React.ReactNode, index: number) => (
                    <React.Fragment key={index}>
                        {slot}
                    </React.Fragment>
                ))}
            </div>
        );
    };

    //------------------------------------------------------
    //------------------------------------------------------
    private readonly _renderLinks = (links: ILinksData[]): JSX.Element => {
        return (
            <div className={cls('links')}>
                {links.map((link: ILinkData, index: number) => (
                    <a
                        key={index}
                        className={index === 0 ? 'msc-cta__primary' : 'msc-cta__secondary'}
                        href={link.linkUrl?.destinationUrl}
                        title={link.linkText}
                        aria-label={link.ariaLabel}
                        target={link.openInNewTab ? '_blank' : undefined}
                        rel='noreferrer'
                        role='button'
                    >
                        {link.linkText}
                    </a>
                ))}
            </div>
        );
    };
}

export default ContainerWithHeader;
